// Register Page
export const registrationInputFields = [
  { placeholderLeft: "Email", requiredLeft: true, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Password", requiredLeft: true, fieldNameLeft: "password", inputTypeLeft: "password", passwordIconLeft: true, requiredRight: true, placeholderRight: "Confirm password", fieldNameRight: "confirmPassword", inputTypeRight: "password", passwordIconRight: true },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Add Restaurant
export const addRestaurantInputFields = [
  { placeholderLeft: "Email", requiredLeft: true, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Password", requiredLeft: true, fieldNameLeft: "password", inputTypeLeft: "password", passwordIconLeft: true, placeholderRight: "Confirm password", requiredRight: true, fieldNameRight: "confirmPassword", inputTypeRight: "password", passwordIconRight: true },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Edit Restaurant
export const editRestaurantInputFields = [
  { placeholderLeft: "Email", requiredLeft: false, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Edit Profile
export const editProfileInputFields = [
  { placeholderLeft: "Email", requiredLeft: false, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Settings
export const settingsInputFields = [
  { placeholderLeft: "App ID", fieldNameLeft: "appId", requiredLeft: true, placeholderRight: "App Name", fieldNameRight: "appName", requiredRight: true, },
  { placeholderLeft: "Access Token", fieldNameLeft: "accessToken", requiredLeft: true, placeholderRight: "Waba ID", fieldNameRight: "wabaId", requiredRight: true, },
  { placeholderLeft: "FB Catelog ID", fieldNameLeft: "fbCatalogId", requiredLeft: true, placeholderRight: "Phone ID", fieldNameRight: "phoneNumberId", requiredRight: true, },
  { placeholderLeft: "Callback URL", fieldNameLeft: "callbackUrl", requiredLeft: true, placeholderRight: "Verify Token", fieldNameRight: "verifyToken", requiredRight: true },
  { placeholderLeft: "Visit Count", requiredLeft: false, fieldNameLeft: "visitCount", placeholderRight: "Reservation Time Interval", requiredRight: false, fieldNameRight: "reservationTimeInterval" },
  { placeholderLeft: "Google Feedback URL", requiredLeft: false, fieldNameLeft: "googleFeedbackUrl" },
]

// Admin Settings
export const adminSettingsInputFields = [
  { placeholderLeft: "Currency", fieldNameLeft: "currency", placeholderRight: "Currency Sign", fieldNameRight: "currencySign" },
  { placeholderLeft: "VAT", fieldNameLeft: "vat" },
]

// Loyalty Program Fields
export const loyaltyProgramInputFields = [
  { placeholderLeft: "Price per coin", fieldNameLeft: "rewardCoinsPrice", placeholderRight: "Reward coins per order", fieldNameRight: "rewardCoinsPerOrder" },
  { placeholderLeft: "Minimum coins required to become eligible to redeem", fieldNameLeft: "minimumRewardedCoins", placeholderRight: "Minimum order value to earn coins", fieldNameRight: "minimumOrderPrice" },
  { placeholderLeft: "Maximum applicable coins per order", fieldNameLeft: "maxRedeemableCoins" },
]

// Role
export const USER_ROLE = {
  SUPER_ADMIN: "superAdmin",
  RESTAURANT_OWNER: "admin",
}

// Message-Types
export const MessageType = {
  Info: 'INFO',
  Error: 'ERROR',
  Warn: 'WARN',
  Success: 'SUCCESS',
};

export const StatusCode = {
  BadRequest: 400,
  UnAuthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  AlreadyExists: 409,
  InternalServerError: 500,
};

// Role
export const API_REQUEST = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
}

// File type
export const FILE_TYPE = ['jpg', 'jpeg', 'png']

// Campaign File type
export const CAMPAIGN_FILE_TYPE =  ['jpg', 'jpeg', 'png', 'pdf', 'mp4', 'mov', 'avi']

// Customers Import File Type
export const EXCEL_FILE_TYPE = ['csv']

// Brand name
export const BrandName = "WePOS.ai"

export const SampleCSV = 'https://wa-campaign.s3.eu-west-2.amazonaws.com/pdf/customers-sample-file.csv'

// Image type
export const ImageType = {
  LOGO: 'Restaurant Logo',
  PRODUCT_IMAGE: 'Product Image',
  CAMPAIGN_IMAGE: 'Campaign Image',
  CAMPAIGN_PDF: 'Campaign PDF',
  CAMPAIGN_VIDEO: 'Campaign Video',
  RESTAURANT_MENU_PDF: 'Restaurant Menu PDF',
}

// Brand name
export const ProductFechingTimeout = 3000

// Image Links
export const ImageLinks = ['https', 'http']

// Restaurant service enum
export const serviceThrough = {
  takeaway: 'Takeaway',
  'dine-in': 'Dine-In',
  'no-contact': 'No-Contact',
  delivery: 'Delivery',
  'Kerbside pickup': 'Kerbside Pickup',
}

// Subscription type
export const subscriptionType = {
  monthly: 'MONTHLY',
  yearly: 'YEARLY'
}

export const multiSelectDropdownStyle = {
  control: (styles) => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minHeight: '43px',
    borderStyle: 'none',
    borderWidth: '0',
    borderColor: '#FFF !important',
    boxShadow: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ED3C5D' : 'transparent',
    '&:hover': {
      backgroundColor: state.isSelected ? '#ED3C5D' : '#F1F5F9',
    }
  }),
}

export const selectDropdownStyle = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: "190px",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '40px',
    borderStyle: 'none',
    borderWidth: '0',
    borderColor: '#FFF !important',
    boxShadow: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ED3C5D' : '#ffffff',
    '&:hover': {
      backgroundColor: state.isSelected ? '#ED3C5D' : '#F1F5F9',
    }
  }),
}

// Country
export const Country = [
  { label: 'India', value: 'IN' },
  { label: 'UK', value: 'UK' }
]

// Country Code
export const CountryCode = {
  IN: '91',
  UK: '44',
}

// Country
export const CountryCodeDropdownOptions = [
  { label: '91', value: '91' },
  { label: '44', value: '44' }
]

// File size in byte
export const MinimumFileSize = 102400;
export const MaximumFileSize = 5242880;

export const RoutesActions = {
  customers: 'RestaurantAdminCustomers',
  tags: 'RestaurantAdminTags',
  tables: 'RestaurantAdminTables',
  categories: 'RestaurantAdminCategories',
  products: 'RestaurantAdminProducts',
  offers: 'RestaurantAdminOffers',
  coupons: 'RestaurantAdminCoupons',
  marketings: 'RestaurantAdminMarketings',
  orders: 'RestaurantAdminOrders',
  reports: 'RestaurantAdminReports',
  reservations: 'RestaurantAdminReservations',
  transactions: 'RestaurantAdminTransactions',
  feedbacks: 'RestaurantAdminFeedbacks',
  chats: 'RestaurantAdminChats',
  notifications: 'RestaurantNotifications',
  templates: 'RestaurantAdminTemplates',
  templatesAnalytics: 'RestaurantAdminTemplatesAnalytics',
  subscription: 'SuperAdminSubscriptions',
  restaurants: 'SuperAdminRestaurants',
  payments: 'SuperAdminPayments',
  subscriptions: 'SuperAdminSubscriptions',
  cms: "SuperAdminCMS",  
}

export const TemplateButtonsDropdown = [
  { label: "None", value: "" },
  { label: "Call to action", value: "action" }
]

export const TemplateButtonsOptions = [
  { label: "Visit Website", value: 'URL' },
  { label: "Call Phone", value: 'PHONE_NUMBER' },
  { label: "Static", value: 'QUICK_REPLY' }
]

export const ProductFetchDelayMessage = "Updating product details, recommended items, and offers may take up to 30 minutes to reflect in the WhatsApp interface, so be patient. If you're not seeing updated data, Try after some time.";
export const TemplateUpdateMessage = "A template can be edited only when it is in a state of Approved, Rejected, or Paused. It can only be edited once within a 24-hour period, up to 10 times per month.";
export const TemplateAnalyticsMessage = "If any template needs to be Re-Run, navigate to the specific campaign and select the 'Re-Run' option to initiate the Re-Run process.";
export const CombinePDFSizeValidationMessage = "The total combined sizes of the PDF files should not be more than 5 MB.";
export const ImportCustomerNote = "Note: Please ensure that the CSV file data is structured with three columns: 'Name' for names, 'WhatsappNumber' for numbers (each phone number must include the country code, e.g., '445551256846'), and 'MarketingObtained' to indicate whether the user wants to receive marketing messages (yes or no). please check sample csv file.";
export const TemplateBodyMessageNote = "Note: {{content}} should represent the message of the campaign. Please add it to the body. The customer will receive a message that is a combination of the body and the campaign message.";

export const PageSizes = [{
  label: 10,
  value: 10,
}, {
  label: 25,
  value: 25,
}, {
  label: 50,
  value: 50,
}, {
  label: 100,
  value: 100,
}];

export const reservationTimeInterval = [{
  label: '15 Mins',
  value: 15,
}, {
  label: '30 Mins',
  value: 30,
}, {
  label: '45 Mins',
  value: 45,
}, {
  label: '60 Mins',
  value: 60,
}];

export const DiscountType = {
  percentage: 'Percentage',
  flat: 'Flat'
}

export const OrderStatus = {
  "ADDED TO CART": 1,
  "ORDER CONFIRMED": 2,
  "ADDED TO CART PARTIALLY": 3,
  "PAYMENT INITIATED": 4,
  "PAYMENT DONE": 5,
  "PAYMENT FAILED": 6,
  "COMPLETE": 7,
  "CANCELLED": 8,
  "PARTIALLY PAID": 9,
}

export const orderTooltipText = [{
  delay: 0,
  placement: "top",
  target: 'sendPaymentLink',
  text: 'Send Payment Link'
}, {
  delay: 0,
  placement: "top",
  target: 'completeOrder',
  text: 'Complete Order'
}, {
  delay: 0,
  placement: "top",
  target: 'edit',
  text: 'Edit'
}]

export const settingTabs = ['App Settings', 'Stripe Account', 'Loyalty Program', 'Chatbot Settings', ] //'Notification Settings'

export const superAdminSettingTabs = ['App Settings', 'Chatbot Settings']

export const ReservationStatus = {
  'PENDING': 0,
  'RESERVED': 1,
  'DECLINED': 2,
  'CANCELLED': 3,
  'COMPLETE': 4,
}

export const SubscriptionPaymentStatus = {
  'FAILED': 0,
  'DONE': 1,
  'PENDING': 2
}

export const paymentSuccessMessage = 'Payment successful. Thank you for your purchase!'
export const paymentFailedMessage = 'Payment failed. Please check your payment information and try again.'

// Report Type
export const ReportType = {
  PRODUCT: 'product',
  CATEGORY: 'category',
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}

export const featurePermissions = {
  campaigns: 'Campaign',
  orders: 'Orders',
  tables: 'Tables',
  coupons: 'Coupons',
  transactions: 'Payment & Transactions',
  hourly: 'Hourly Reports',
  daily: 'Daily Reports',
  weekly: 'Weekly Reports',
  categories: 'Categories',
  pos: "POS Integration",
  products: 'Products',
  customers: 'Customers',
  tags: 'Tags',
  reservations: 'Reservations',
  feedbacks: 'Feedbacks',
  chats: 'Chats',
  chatflow: 'No-Code ChatBot',
  sharedteaminbox: 'Shared Team Inbox',
  catalogs: 'Catalogs',
  customnotification: 'Custom Notification',
  customerengagement: 'Customer Engagement',
  wpchatlink: 'WhatsApp Chat Link',
  wpqrcode: 'WhatsApp QR Code',
  wpchatbutton: 'WhatsApp Chat Button',
  clicktowpad: 'Click to WhatsApp Ad',
  chatwidget: 'Chat-Widget',
  loyalty: 'Loyalty Program',
  monthly: 'Monthly Reports',
  yearly: 'Yearly Reports',
  product: 'Products Reports',
  category: 'Category Reports',
}

export const ordersChartTooltips = {
  callbacks: {
    label: function (item, data) {
      var label = data.datasets[item.datasetIndex].label || "";
      var yLabel = item.yLabel;
      var content = "";
      if (data.datasets.length > 1) {
        content += label;
      }
      content += yLabel;
      return content;
    }
  }
}

export const ordersChartscales = {
  yAxes: [
    {
      ticks: {
        callback: function (value) {
          return value;
        }
      }
    }
  ]
}

export const calenderViewMode = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
}

// States
export const UK_States = ['Aberconwy and Colwyn',
  'Aberdeen City',
  'Aberdeenshire',
  'Anglesey',
  'Angus',
  'Antrim',
  'Argyll and Bute',
  'Armagh',
  'Avon',
  'Ayrshire',
  'Bath and NE Somerset',
  'Bedfordshire',
  'Belfast',
  'Berkshire',
  'Berwickshire',
  'BFPO',
  'Blaenau Gwent',
  'Buckinghamshire',
  'Caernarfonshire',
  'Caerphilly',
  'Caithness',
  'Cambridgeshire',
  'Cardiff',
  'Cardiganshire',
  'Carmarthenshire',
  'Ceredigion',
  'Channel Islands',
  'Cheshire',
  'City of Bristol',
  'Clackmannanshire',
  'Clwyd',
  'Conwy',
  'Cornwall/Scilly',
  'Cumbria',
  'Denbighshire',
  'Derbyshire',
  'Derry/Londonderry',
  'Devon',
  'Dorset',
  'Down',
  'Dumfries and Galloway',
  'Dunbartonshire',
  'Dundee',
  'Durham',
  'Dyfed',
  'East Ayrshire',
  'East Dunbartonshire',
  'East Lothian',
  'East Renfrewshire',
  'East Riding Yorkshire',
  'East Sussex',
  'Edinburgh',
  'England',
  'Essex',
  'Falkirk',
  'Fermanagh',
  'Fife',
  'Flintshire',
  'Glasgow',
  'Gloucestershire',
  'Greater London',
  'Greater Manchester',
  'Gwent',
  'Gwynedd',
  'Hampshire',
  'Hartlepool',
  'Hereford and Worcester',
  'Hertfordshire',
  'Highlands',
  'Inverclyde',
  'Inverness-Shire',
  'Isle of Man',
  'Isle of Wight',
  'Kent',
  'Kincardinshire',
  'Kingston Upon Hull',
  'Kinross-Shire',
  'Kirklees',
  'Lanarkshire',
  'Lancashire',
  'Leicestershire',
  'Lincolnshire',
  'Londonderry',
  'Merseyside',
  'Merthyr Tydfil',
  'Mid Glamorgan',
  'Mid Lothian',
  'Middlesex',
  'Monmouthshire',
  'Moray',
  'Neath & Port Talbot',
  'Newport',
  'Norfolk',
  'North Ayrshire',
  'North East Lincolnshire',
  'North Lanarkshire',
  'North Lincolnshire',
  'North Somerset',
  'North Yorkshire',
  'Northamptonshire',
  'Northern Ireland',
  'Northumberland',
  'Nottinghamshire',
  'Orkney and Shetland Isles',
  'Oxfordshire',
  'Pembrokeshire',
  'Perth and Kinross',
  'Powys',
  'Redcar and Cleveland',
  'Renfrewshire',
  'Rhonda Cynon Taff',
  'Rutland',
  'Scottish Borders',
  'Shetland',
  'Shropshire',
  'Somerset',
  'South Ayrshire',
  'South Glamorgan',
  'South Gloucesteshire',
  'South Lanarkshire',
  'South Yorkshire',
  'Staffordshire',
  'Stirling',
  'Stockton On Tees',
  'Suffolk',
  'Surrey',
  'Swansea',
  'Torfaen',
  'Tyne and Wear',
  'Tyrone',
  'Vale Of Glamorgan',
  'Wales',
  'Warwickshire',
  'West Berkshire',
  'West Dunbartonshire',
  'West Glamorgan',
  'West Lothian',
  'West Midlands',
  'West Sussex',
  'West Yorkshire',
  'Western Isles',
  'Wiltshire',
  'Wirral',
  'Worcestershire',
  'Wrexham',
  'York']