import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody } from 'reactstrap';
import { useState } from 'react';

const ScriptModal = ({ modal, toggleModal, generatedScript }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedScript)
      .then(() => setCopySuccess('Copied!'))
      .catch(() => setCopySuccess('Failed to copy'));
    
    // Clear the message after 2 seconds
    setTimeout(() => setCopySuccess(''), 2000);
  };

  return (
    <Modal className="modal-dialog-centered edit-modal" isOpen={modal} toggle={toggleModal} size="md">
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
         Copy and paste this code before the end of Body tag on every page of your website.
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <ModalBody>
        <pre>{generatedScript}</pre>
        <button onClick={handleCopy} className="btn btn-outline-primary mt-2">
          <FontAwesomeIcon icon={faClipboard} /> Copy to Clipboard
        </button>
        {copySuccess && <div className="text-success mt-2">{copySuccess}</div>} 
       </ModalBody>
    </Modal>
  );
};

export default ScriptModal;
