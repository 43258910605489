export const firebaseConfig = {
    // Your web app's Firebase configuration here
    // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
    apiKey: 'AIzaSyDEvh_TdKFnavUu4UxyIA1nw4vaM7TGtR4',
    authDomain: 'wepos-3d25f.firebaseapp.com',
    databaseURL: 'https://wepos-3d25f.firebaseio.com',
    projectId: 'wepos-3d25f',
    storageBucket: 'wepos-3d25f.firebasestorage.app',
    messagingSenderId: '188533367818',
    appId: '1:188533367818:web:51e47c506e57914d87ebf3',
    measurementId: 'G-MEASUREMENT_ID'
  };
  
  export const vapidKey = 'BHb7moZb6FyRqLXAFdmEQELFCX3Sg6kASNH3rBcDRWylMghGIYTAf7ocLDD9Ncy23jBM-1HpAm0s0NRAznyfV50';
  
  export const AllKeys = {
    "subject": "mailto: <info@wepos.ai>",
    "publicKey": "BO-rf05j9YR8Y8cC7BAf2O-hp1BqQ-gYhgLvskrg_MbwtRwpX70y0F90_FnlUT1nKduTJDPCeYOX5SgKTowPZaM",
    "privateKey": "eGWslykyw_cJikeUW6Wj85W5CYQ65RgVC_xQMnmO3Xg"
  }